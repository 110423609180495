import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="courier-refund"
export default class extends Controller {
  static targets = [
    "packageIncident",
    "damageDetails",
    "itemSelection",
    "lateDelivery",
  ];

  connect() {
    this.updateVisibility();
  }

  updateVisibility() {
    const selectedValue = this.packageIncidentTarget.value;

    this.damageDetailsTarget.style.display =
      selectedValue === "UBER_DAMAGED_ITEM" ? "block" : "none";

    this.itemSelectionTarget.style.display =
      selectedValue === "UBER_DAMAGED_ITEM" ? "block" : "none";

    this.lateDeliveryTarget.style.display =
      selectedValue === "UBER_NEVER_RECEIVED_ORDER" ||
      selectedValue === "UBER_ORDER_DELIVERED_LATE" ||
      selectedValue === "UBER_HAD_TO_PREPARE_ORDER_AGAIN"
        ? "block"
        : "none";
  }
}
